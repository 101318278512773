import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./en.json";
import fr from "./fr.json";

const resources = {
	en: {
		translation: en,
	},
	fr: {
		translation: fr,
	},
};

i18n
	.use(LanguageDetector) // detect user language
	.use(initReactI18next) // pass the i18n instance to react-i18next
	.init({
		resources,
		fallbackLng: "en", // fallback language is english
		debug: process.env.NODE_ENV === "development", // helps finding issues with loading not working
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});
export default i18n;
