import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { signOutWrapper } from "@met-genius/common/src/api/firebase/auth";

export const LoginOrProfileButton = ({ isConnected }: { isConnected: boolean }) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
	const handleCloseMenu = () => setAnchorEl(null);

	return isConnected ? (
		<div>
			<IconButton
				size="large"
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={handleOpenMenu}
				color="inherit">
				<AccountCircle />
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={!!anchorEl}
				onClose={handleCloseMenu}>
				<MenuItem onClick={handleCloseMenu}>{t("Billing")}</MenuItem>
				<MenuItem onClick={handleCloseMenu}>{t("Settings")}</MenuItem>
				<MenuItem onClick={() => signOutWrapper()}>{t("SignOut")}</MenuItem>
			</Menu>
		</div>
	) : (
		<Button color="inherit">{t("Login")}</Button>
	);
};
