import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { History } from "../pages/History";
import { Reports } from "../pages/Reports";
import { Billing } from "../pages/Billing";
import { Settings } from "../pages/Settings";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import BarChartIcon from "@mui/icons-material/BarChart";
import HistoryIcon from "@mui/icons-material/History";
import PaymentIcon from "@mui/icons-material/Payment";
import { TFunction } from "i18next";
import { signOutWrapper } from "@met-genius/common/src/api/firebase/auth";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";

export const Menus = (t: TFunction<"translation", undefined>) => {
	return [
		{ path: "/", name: t("Home"), icon: <HomeIcon />, level: 0 },
		{ path: "/history", name: t("History"), icon: <HistoryIcon />, level: 0 },
		{ path: "/reports", name: t("Reports"), icon: <BarChartIcon />, level: 0 },
		{ path: "/billing", name: t("Billing"), icon: <PaymentIcon />, level: 1 },
		{ path: "/settings", name: t("Settings"), icon: <SettingsIcon />, level: 1 },
		{ path: "/signOut", name: t("SignOut"), icon: <LogoutIcon />, level: 1, onClick: () => signOutWrapper() },
	];
};
export const RoutesContent = () => (
	<Routes>
		<Route path="/" element={<Home />} />
		<Route path="/history" element={<History />} />
		<Route path="/reports" element={<Reports />} />
		<Route path="/billing" element={<Billing />} />
		<Route path="/settings" element={<Settings />} />
		<Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* TODO UPDATE THIS */}
	</Routes>
);
