import React from "react";

export const PrivacyPolicy = () => {
	return (
		<div style={{ paddingLeft: 20 }}>
			<h1>Politique de Confidentialité de MetGenius</h1>
			<p>
				<strong>Dernière mise à jour :</strong> 22 mars 2024
			</p>
			<p>
				Cette politique de confidentialité décrit la manière dont MetGenius ("nous", "notre" ou "nos") collecte, utilise et partage vos
				informations lorsque vous utilisez notre application MetGenius (l' "Application").
			</p>
			<h2>Collecte et Utilisation des Informations</h2>
			<p>Nous ne collectons aucune information personnelle identifiable lorsque vous utilisez l'Application.</p>
			<p>
				Cependant, l'Application peut nécessiter l'accès à certaines autorisations de votre navigateur pour fonctionner correctement. Nous
				demandons les autorisations suivantes avec les justifications suivantes :
			</p>
			<ul>
				<li>
					<strong>Permission "activeTab" :</strong> Cette permission est nécessaire pour permettre à MetGenius d'interagir avec l'onglet
					actif du navigateur. Lorsque vous lancez MetGenius pendant un entretien technique, il doit être en mesure de lire les questions
					posées par le recruteur et d'y répondre en temps réel. La permission "activeTab" autorise MetGenius à accéder au contenu de
					l'onglet actif, ce qui lui permet de fonctionner de manière transparente et efficace.
				</li>
				<li>
					<strong>Permission "identity" :</strong> Cette permission est importante pour assurer la sécurité de l'utilisateur lors de
					l'utilisation de MetGenius. En demandant l'accès à l'identité de l'utilisateur, MetGenius peut s'assurer qu'il est bien utilisé
					par la bonne personne autorisée. Cela garantit également que les informations sensibles générées pendant l'entretien restent
					confidentielles et ne sont accessibles qu'à l'utilisateur autorisé.
				</li>
				<li>
					<strong>Autorisation d'accès à l'hôte :</strong> Cette extension doit être uniquement accessible pour Google Meet.
				</li>
			</ul>
			<p>
				Lors de l'utilisation de l'Application, aucune donnée utilisateur n'est stockée ou enregistrée sur nos serveurs ou sur tout autre
				support.
			</p>
			<h2>Partage des Informations</h2>
			<p>Nous ne partageons aucune information personnelle identifiable avec des tiers.</p>
			<h2>Sécurité</h2>
			<p>
				La sécurité de vos informations est importante pour nous. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet
				ou de stockage électronique n'est sécurisée à 100 %. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables
				pour protéger vos informations personnelles, nous ne pouvons pas garantir leur sécurité absolue.
			</p>
			<h2>Liens vers d'autres Sites</h2>
			<p>
				L'Application peut contenir des liens vers d'autres sites. Si vous cliquez sur un lien tiers, vous serez redirigé vers ce site.
				Notez que ces sites externes ne sont pas exploités par nous. Par conséquent, nous vous recommandons vivement de consulter la
				politique de confidentialité de ces sites web. Nous n'avons aucun contrôle sur le contenu, les politiques de confidentialité ou les
				pratiques des sites ou services tiers, et nous déclinons toute responsabilité à cet égard.
			</p>
			<h2>Modification de notre Politique de Confidentialité</h2>
			<p>
				Nous nous réservons le droit de mettre à jour ou de modifier notre Politique de Confidentialité à tout moment. Vous devez consulter
				périodiquement cette Politique de Confidentialité pour toute modification. Les modifications apportées à cette Politique de
				Confidentialité entrent en vigueur dès leur publication sur cette page.
			</p>
			<h2>Contactez-nous</h2>
			<p>
				Si vous avez des questions ou des préoccupations concernant notre Politique de Confidentialité, n'hésitez pas à nous contacter à
				l'adresse suivante : contact@previsy.com.
			</p>
		</div>
	);
};
