import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
	name: "auth",
	initialState: {
		isLoading: true,
		userId: null,
	},
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload;
		},
		setUserId: (state, action) => {
			state.userId = action.payload;
		},
	},
});
export const { setIsLoading, setUserId } = authSlice.actions;
export default authSlice.reducer;
