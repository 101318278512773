import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import React, { useState } from "react";
import { LightGray } from "../../../helpers/colors";

interface AuthButtonProps extends Omit<LoadingButtonProps, "onClick"> {
	onClickAsync: () => Promise<any> | undefined;
}

export const AuthButton = ({ onClickAsync, startIcon, ...props }: AuthButtonProps) => {
	const handleAsyncAction = async () => {
		try {
			setIsLoading(true);
			await onClickAsync();
		} catch (e) {
			alert(e);
		} finally {
			setIsLoading(false);
		}
	};
	const [isLoading, setIsLoading] = useState(false);
	return (
		<LoadingButton
			{...props}
			style={{
				textTransform: "none",
				marginTop: 20,
				height: 47,
				fontWeight: 600,
				backgroundColor: startIcon && !isLoading ? "white" : undefined,
				color: startIcon && !isLoading ? LightGray : undefined,
			}}
			startIcon={
				startIcon
					? React.cloneElement(startIcon as React.ReactElement<any>, {
							style: {
								width: 18,
								height: 18,
							},
					  })
					: null
			}
			loading={isLoading}
			fullWidth
			size="large"
			variant={props.variant ? props.variant : "contained"}
			onClick={handleAsyncAction}
		/>
	);
};
