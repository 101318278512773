import { capitalize } from "@mui/material";

export enum AuthProviderId {
	EmailPassword = "password",
	Google = "google.com",
	Microsoft = "microsoft.com",
}

export const GetProviderName = (providerId: AuthProviderId) =>
	providerId === AuthProviderId.EmailPassword ? "Email" : capitalize(providerId?.split(".")[0]);
