import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface MenuType {
	path: string;
	name: string;
	icon: React.ReactElement;
	onClick?: () => void;
	level: number;
}

interface MenusProps {
	menus: MenuType[];
	isOpen: boolean;
}

export const Menus = ({ menus, isOpen }: MenusProps) => {
	const navigate = useNavigate();
	return (
		<>
			{menus.map(({ path, name, icon, onClick }) => (
				<ListItem key={path} disablePadding sx={{ display: "block" }}>
					<ListItemButton
						onClick={() => {
							onClick ? onClick() : navigate(path);
						}}
						sx={{
							minHeight: 48,
							justifyContent: isOpen ? "initial" : "center",
							px: 2.5,
						}}>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: isOpen ? 3 : "auto",
								justifyContent: "center",
							}}>
							{icon}
						</ListItemIcon>
						<ListItemText primary={name} sx={{ opacity: isOpen ? 1 : 0 }} />
					</ListItemButton>
				</ListItem>
			))}
		</>
	);
};
