import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyCIUm-FbOC-mvYLvUM0LKNXHgKmPn0OHeI",
	authDomain: "interviewhelper-380703.firebaseapp.com",
	projectId: "interviewhelper-380703",
	storageBucket: "interviewhelper-380703.appspot.com",
	messagingSenderId: "6700168872",
	appId: "1:6700168872:web:ecf1537625349ecd2b749d",
	measurementId: "G-9JLQHWNEYE",
};

const app = initializeApp(firebaseConfig);
initializeFirestore(app, { ignoreUndefinedProperties: true });
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore };
