import "./App.css";
import Box from "@mui/material/Box";
import { AppBarWithDrawer } from "./components/AppBarWithDrawer";
import { DrawerHeader } from "./components/AppBarWithDrawer/DrawerHeader";
import { Menus, RoutesContent } from "./routers/RoutesContent";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { Loading, SignInOrSignUp, auth } from "@met-genius/common";
import { useEffect } from "react";
import { Unsubscribe, onAuthStateChanged } from "firebase/auth";
import { setIsLoading, setUserId } from "./redux/slices/authSlice";
import { useTranslation } from "react-i18next";
import { PaymentForm } from "react-square-web-payments-sdk";
import { Context } from "@met-genius/common/src/types/Context";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

export const App = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	useEffect(() => {
		let unListenAuth: Unsubscribe;
		unListenAuth = onAuthStateChanged(auth, user => {
			dispatch(setUserId(user?.uid));
			dispatch(setIsLoading(false));
		});
		return () => {
			unListenAuth?.();
		};
	}, [dispatch]);

	const { isLoading, userId } = useSelector((state: RootState) => state.auth);
	const { creditToBuy } = useSelector((state: RootState) => state.localData);
	const { pathname } = useLocation();
	return (
		<>
			{isLoading ? (
				<Loading />
			) : pathname === "/privacy-policy" ? (
				<PrivacyPolicy />
			) : userId ? (
				<PaymentForm
					applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID!!}
					locationId={process.env.REACT_APP_SQUARE_LOCATION_ID!!}
					cardTokenizeResponseReceived={async (res, verifiedBuyer) => {
						const response = await fetch(process.env.REACT_APP_SQUARE_PROCESS_PAYMENT_URL!!, {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify({
								sourceId: res.token,
								creditToBuy: creditToBuy,
							}),
						});
					}}>
					<Box sx={{ display: "flex", flexGrow: 1 }}>
						<AppBarWithDrawer title="MetGenius" menus={Menus(t)} isConnected={!!userId} />
						<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
							<DrawerHeader />
							<RoutesContent />
						</Box>
					</Box>
				</PaymentForm>
			) : (
				<SignInOrSignUp context={Context.Dashboard} />
			)}
		</>
	);
};
