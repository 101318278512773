import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { AppBar } from "./AppBar";
import { Drawer } from "./Drawer";
import { DrawerHeader } from "./DrawerHeader";
import { LoginOrProfileButton } from "./LoginOrProfileButton";
import { MenuType, Menus } from "./Menus";

interface AppBarWithDrawerProps {
	title: string;
	menus: MenuType[];
	isConnected: boolean;
}

export const AppBarWithDrawer = ({ title, menus, isConnected }: AppBarWithDrawerProps) => {
	const theme = useTheme();
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<AppBar position="fixed" open={isOpen}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={() => setIsOpen(true)}
						edge="start"
						sx={{
							marginRight: 5,
							...(isOpen && { display: "none" }),
						}}>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
						{title}
					</Typography>
					<LoginOrProfileButton isConnected={isConnected} />
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={isOpen}>
				<DrawerHeader>
					<IconButton onClick={() => setIsOpen(false)}>{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					<Menus menus={menus.filter(m => m.level === 0)} isOpen={isOpen} />
				</List>
				<Divider />
				<Menus menus={menus.filter(m => m.level === 1)} isOpen={isOpen} />
			</Drawer>
		</>
	);
};
