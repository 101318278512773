import { IconButton, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AuthButton } from "./AuthButton";
import { AccountLink } from "./AccountLink";
import { AccountLinkProps } from "./AccountLink/AccountLinkProps";
import { TextFieldPassword } from "./TextFieldPassword";
import { OAuthProvider, linkWithRedirect } from "firebase/auth";
import { ButtonLink } from "../ButtonLink";
import { conditionEmail } from "../../helpers/validation";
import { Blue, LightGray } from "../../helpers/colors";
import { AuthProviderId, GetProviderName } from "../../types/AuthProvider";
import { GoogleLogo } from "../..";
import { Context } from "../../types/Context";
import { resetPassword, signIn, signUp } from "../../api/firebase/auth";

enum ScreenType {
	SIGN_IN = "SIGN_IN",
	SIGN_UP = "SIGN_UP",
	FORGOT_PASSWORD = "FORGOT_PASSWORD",
	LINK_ACCOUNT = "LINK_ACCOUNT",
}
const DEFAULT_SCREEN = ScreenType.SIGN_UP;

interface SignInOrSignUpProps {
	context: Context;
}

export const SignInOrSignUp = ({ context }: SignInOrSignUpProps) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [screenType, setScreenType] = useState(DEFAULT_SCREEN);
	const [accountLinkData, setAccountLinkData] = useState<AccountLinkProps>();

	const getScreenText = () => {
		switch (screenType) {
			case ScreenType.SIGN_IN:
				return t("SignIn");
			case ScreenType.SIGN_UP:
				return t("SignUp");
			case ScreenType.FORGOT_PASSWORD:
				return t("ResetPassword");
			case ScreenType.LINK_ACCOUNT:
				return t("AnAccountAlreadyExists");
		}
	};

	const getAuthText = () => `${screenType === ScreenType.SIGN_IN ? t("SignInWith") : t("SignUpWith")}`;

	const handleAccountExistWithDifferentCred = (data: AccountLinkProps) => {
		setAccountLinkData(data);
		setScreenType(ScreenType.LINK_ACCOUNT);
	};

	return (
		<div
			style={{
				display: "flex",
				flex: 1,
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				backgroundColor: "white",
				padding: 20,
			}}>
			{screenType === ScreenType.FORGOT_PASSWORD && (
				<div style={{ position: "absolute", top: 0, left: 0, padding: 20 }}>
					<IconButton onClick={() => setScreenType(ScreenType.SIGN_IN)}>
						<ArrowBackIcon />
					</IconButton>
				</div>
			)}
			<h2 style={{ marginBottom: 40, textAlign: "center", color: Blue }}>MetGenius</h2>
			<h3 style={{ marginBottom: 40, textAlign: "center", color: LightGray }}>{getScreenText()}</h3>

			{screenType === ScreenType.LINK_ACCOUNT && accountLinkData?.email && accountLinkData?.newProviderId ? (
				<AccountLink
					{...accountLinkData}
					password={password}
					setPassword={setPassword}
					onContinue={async () => {
						const result = await signIn(
							t,
							accountLinkData.oldProviderId,
							handleAccountExistWithDifferentCred,
							accountLinkData.email,
							password
						);
						if (result) {
							await linkWithRedirect(result.user, new OAuthProvider(accountLinkData.newProviderId)).then(r => alert(r));
						}
					}}
					onCancel={() => setScreenType(DEFAULT_SCREEN)}
				/>
			) : (
				<>
					<TextField
						inputProps={{ style: { height: "30px" } }}
						size="small"
						type="email"
						fullWidth
						required
						id="outlined-basic"
						label={t("Email")}
						variant="outlined"
						value={email}
						onChange={({ target: { value } }) => setEmail(value)}
					/>
					{screenType !== ScreenType.FORGOT_PASSWORD && (
						<>
							<TextFieldPassword password={password} setPassword={setPassword} />
							{screenType === ScreenType.SIGN_IN && (
								<ButtonLink style={{ alignSelf: "flex-end" }} onClick={() => setScreenType(ScreenType.FORGOT_PASSWORD)}>
									{t("ForgotPasswordQuestion")}
								</ButtonLink>
							)}
						</>
					)}
					<AuthButton
						disabled={!conditionEmail.test(email) || (screenType !== ScreenType.FORGOT_PASSWORD && !password)}
						onClickAsync={() => {
							switch (screenType) {
								case ScreenType.SIGN_IN:
									return signIn(t, AuthProviderId.EmailPassword, handleAccountExistWithDifferentCred, email, password);
								case ScreenType.SIGN_UP:
									return signUp(t, email, password);
								case ScreenType.FORGOT_PASSWORD:
									return resetPassword(t, email);
							}
						}}>
						{getScreenText()}
					</AuthButton>
					{screenType !== ScreenType.FORGOT_PASSWORD && (
						<>
							<AuthButton
								startIcon={<GoogleLogo />}
								onClickAsync={async () =>
									//TODO add context chrome/firefox etc...
									{
										context === Context.Extension
											? chrome.runtime.sendMessage({ type: "getAuthToken" }, accessToken =>
													signIn(t, AuthProviderId.Google, handleAccountExistWithDifferentCred, undefined, undefined, accessToken)
											  )
											: signIn(t, AuthProviderId.Google, handleAccountExistWithDifferentCred);
									}
								}>
								{`${getAuthText()} ${GetProviderName(AuthProviderId.Google)}`}
							</AuthButton>
							{/* <AuthButton
								startIcon={<MicrosoftLogo />}
								onClickAsync={async () =>
									//TODO add context chrome/firefox etc...
									chrome.runtime?.sendMessage?.({ type: "launchWebAuthFlow" }, url => {
										const tokenMatch = url.match(/access_token=([^&]+)/);
										const token = tokenMatch ? tokenMatch[1] : null;
										if (token) {
											signIn(t, AuthProviderId.Microsoft, handleAccountExistWithDifferentCred, undefined, undefined, token);
										} else {
											alert("Unable to retrieve the access token for the Microsoft provider using the launchWebAuthFlow method");
										}
									})
								}>
								{`${getAuthText()} ${GetProviderName(AuthProviderId.Microsoft)}`}
							</AuthButton> */}
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 40 }}>
								<Typography style={{ marginTop: 5, color: LightGray }} fontSize="12px" fontWeight={600}>
									{screenType === ScreenType.SIGN_IN ? t("DontHaveAnAccountYetQuestion") : t("AlreadyHaveAnAccountQuestion")}
									&nbsp;
								</Typography>
								<ButtonLink onClick={() => setScreenType(screenType === ScreenType.SIGN_IN ? ScreenType.SIGN_UP : ScreenType.SIGN_IN)}>
									{screenType === ScreenType.SIGN_IN ? t("SignUp") : t("SignIn")}
								</ButtonLink>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};
