import { Link, LinkProps, useTheme } from "@mui/material";
import { Blue } from "../../helpers/colors";

export const ButtonLink = (props: LinkProps) => {
	const theme = useTheme();
	return (
		<Link
			{...props}
			underline="hover"
			sx={{
				marginTop: "5px",
				fontSize: "12px",
				fontWeight: 600,
				color: Blue,
				"&:hover": {
					cursor: "pointer",
					color: theme.palette.primary.main,
					textDecorationColor: theme.palette.primary.main,
				},
			}}
		/>
	);
};
