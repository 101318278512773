import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

interface TextFieldPasswordProps {
	password: string;
	setPassword: Dispatch<SetStateAction<string>>;
}

export const TextFieldPassword = ({ password, setPassword }: TextFieldPasswordProps) => {
	const [showPassword, setShowPassword] = useState(false);
	const { t } = useTranslation();
	return (
		<TextField
			inputProps={{ style: { height: "30px" } }}
			size="small"
			style={{ marginTop: 20 }}
			type={showPassword ? "text" : "password"}
			fullWidth
			required
			id="outlined-basic"
			label={t("Password")}
			variant="outlined"
			value={password}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(prev => !prev)}>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				),
			}}
			onChange={({ target: { value } }) => setPassword(value)}
		/>
	);
};
