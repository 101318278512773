import { useTranslation } from "react-i18next";
import { AuthButton } from "../AuthButton";
import { AccountLinkProps } from "./AccountLinkProps";
import { TextFieldPassword } from "../TextFieldPassword";
import { Dispatch, SetStateAction } from "react";
import { AuthProviderId, GetProviderName } from "../../../types/AuthProvider";

interface Props {
	onContinue: () => Promise<void>;
	onCancel: () => void;
	password: string;
	setPassword: Dispatch<SetStateAction<string>>;
}

export const AccountLink = ({
	email,
	oldProviderId,
	newProviderId,
	onContinue,
	onCancel,
	password,
	setPassword,
}: AccountLinkProps & Props) => {
	const { t } = useTranslation();
	return (
		<div>
			<p>{t("AccountLinkInfo", { email: email })}</p>
			<p>{t("AccountLinkQuestion", { providerId: GetProviderName(newProviderId) })}</p>
			{oldProviderId === AuthProviderId.EmailPassword && <TextFieldPassword password={password} setPassword={setPassword} />}
			<AuthButton disabled={oldProviderId === AuthProviderId.EmailPassword && !password} onClickAsync={onContinue}>
				{t("Continue")}
			</AuthButton>
			<AuthButton variant="outlined" onClickAsync={async () => onCancel()}>
				{t("Cancel")}
			</AuthButton>
		</div>
	);
};
