import { Input } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CreditCard } from "react-square-web-payments-sdk";
import { RootState } from "../../redux/store";
import { setCreditToBuy } from "../../redux/slices/localDataSlice";
import { getUserDetails } from "@met-genius/common/src/api/firebase/firestore";

export const Billing = () => {
	const { t } = useTranslation();
	const [userDetails, setUserDetails] = useState<any>(undefined);
	const { creditToBuy } = useSelector((state: RootState) => state.localData);
	const dispatch = useDispatch();

	const pricePerCreditInCent = 4999;
	const numberOfCentsInOneUnit = 100;
	const currency = "€";
	useEffect(() => {
		getUserDetails().then(userDetails => setUserDetails(userDetails));
	}, []);
	return (
		<div>
			{`${t("NumberOfCredit")}: ${userDetails ? userDetails.credit : "Loading..."}`}
			<br />
			<br />
			<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
				<div>Acheter des crédits: </div>
				<Input
					onChange={v => {
						const parsedValue = parseInt(v.target.value);
						dispatch(setCreditToBuy(isNaN(parsedValue) ? 0 : parsedValue));
					}}
				/>
			</div>
			<div>
				Prix: {(creditToBuy * pricePerCreditInCent) / numberOfCentsInOneUnit}
				{currency}
			</div>
			<CreditCard />
		</div>
	);
};
