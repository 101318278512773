import { createSlice } from "@reduxjs/toolkit";

const localDataSlice = createSlice({
	name: "localData",
	initialState: {
		creditToBuy: 0,
	},
	reducers: {
		setCreditToBuy: (state, action) => {
			state.creditToBuy = action.payload;
		},
	},
});
export const { setCreditToBuy } = localDataSlice.actions;
export default localDataSlice.reducer;
