import {
	OAuthProvider,
	createUserWithEmailAndPassword,
	fetchSignInMethodsForEmail,
	getAdditionalUserInfo,
	sendPasswordResetEmail,
	signInWithCredential,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
} from "firebase/auth";
import { auth } from "./config";
import { AuthProviderId } from "../../types/AuthProvider";
import { AccountLinkProps } from "../../components/SignInOrSignUp/AccountLink/AccountLinkProps";
import { updateUserDetails } from "./firestore";
import { Translation } from "../../types/Translation";

export const signUp = (t: Translation, email: string, password: string) => {
	try {
		if (email && password) {
			return createUserWithEmailAndPassword(auth, email, password)
				.then(userCred => updateUserDetails(t, { email: userCred.user.email as string }, true))
				.catch(e => alert(e.message));
		}
	} catch (e) {
		alert(e);
	}
};

const signInWithProvider = async (
	t: Translation,
	providerId: AuthProviderId,
	onAccountExistWithDifferentCred: ({ email, oldProviderId, newProviderId }: AccountLinkProps) => void,
	token?: string
) => {
	const providerInstance = new OAuthProvider(providerId);
	providerInstance.setCustomParameters({
		prompt: "select_account",
	});

	//TODO: warning rollback firebase auth created if database write failed. Use transaction(batch/commit firebase)
	return (
		token
			? providerId === AuthProviderId.Microsoft
				? signInWithCustomToken(auth, token) // chrome identity don't allow microsoft token
				: signInWithCredential(auth, providerInstance.credential({ accessToken: token })) // for chrome
			: signInWithPopup(auth, providerInstance)
	) // for SAAS
		.then(async userCred => {
			const { isNewUser } = getAdditionalUserInfo(userCred) || {};
			updateUserDetails(t, { email: userCred.user.email as string }, isNewUser);
		})
		.catch(async e => {
			if (e.code === "auth/account-exists-with-different-credential") {
				const providerIds = await fetchSignInMethodsForEmail(auth, e.customData.email);
				onAccountExistWithDifferentCred({
					email: e.customData.email,
					oldProviderId: providerIds[0] as AuthProviderId,
					newProviderId: providerId,
				});
			} else if (e.code === "auth/popup-closed-by-user" || e.code === "auth/cancelled-popup-request") {
				return undefined;
			} else {
				alert(e);
			}
		});
};

export const signIn = async (
	t: Translation,
	authProviderId: AuthProviderId,
	onAccountExistWithDifferentCred: ({ email, oldProviderId, newProviderId }: AccountLinkProps) => void,
	email?: string,
	password?: string,
	token?: string
) => {
	try {
		switch (authProviderId) {
			case AuthProviderId.EmailPassword:
				return email && password ? signInWithEmailAndPassword(auth, email, password).catch(e => alert(e.message)) : undefined;
			case AuthProviderId.Google:
			case AuthProviderId.Microsoft:
				return signInWithProvider(t, authProviderId, onAccountExistWithDifferentCred, token);
		}
	} catch (e) {
		alert(e);
	}
};

export const resetPassword = (t: Translation, email: string) => {
	if (email) {
		return sendPasswordResetEmail(auth, email)
			.then(() => alert(t("ResetPasswordLinkSent")))
			.catch(e => alert(e.message));
	}
};

export const signOutWrapper = () => signOut(auth).catch(e => alert(e.message));
