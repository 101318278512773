import { sendEmailVerification } from "firebase/auth";
import { auth, firestore } from "./config";
import { doc, getDoc, increment, setDoc } from "firebase/firestore";
import { AuthProviderId } from "../../types/AuthProvider";
import { Translation } from "../../types/Translation";
import { UserDetail } from "../../types/UserDetail";

export const collections = {
	users: "users",
	deletedUsers: "deletedUsers",
};

export const getProviderId = () => auth.currentUser?.providerData?.[0]?.providerId as AuthProviderId;

export const getUserCollection = (documentPath?: string) =>
	doc(firestore, collections.users, `${auth.currentUser?.uid}${documentPath ? "/" + documentPath : ""}`);

export const getUserDetails = async () => {
	try {
		return (await getDoc(getUserCollection())).data();
	} catch (e) {
		alert(e);
		return undefined;
	}
};
export const updateUserDetails = (t: Translation, { email }: UserDetail, isCreatingUser?: boolean, setVersion?: boolean) => {
	let data = { email: email.toLowerCase() } as UserDetail;
	// const currentVersion = chrome?.runtime?.getManifest?.()?.version;
	if (isCreatingUser) {
		if (auth.currentUser) {
			return fetch(process.env.REACT_APP_CREATE_USER_DETAILS_URL!!, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					email: data.email,
				}),
			}).then(() => sendEmailVerification(auth.currentUser!!).then(() => alert(t("VerifyAccountMessage"))));
		} else {
			return null;
			//throw crashalitycs errors
		}
	} else {
		return null;
	}
	// if (isCreatingUser || setVersion) {
	//   data.version = currentVersion
	// }
};

// TODO remove this et remettre la règle firestore
export const incrementCredit_TO_REMOVE = (credit: number) => setDoc(getUserCollection(), { credit: increment(credit) }, { merge: true });
